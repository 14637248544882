import React, { FC } from "react";
import Button from "../Button/Button";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";

import "./Footer.scss";
import { Link } from "react-router-dom";
import { ArrowRight, Facebook, Instagram } from "../../assets/icons";
import { scrollToTop } from "../../utils/scrollToTop";
const Footer: FC = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <main>
          <div className="footer__content">
            <Logo className="logo" />
            <div className="title">We would love to hear from you.</div>
            <p>Feel free to reach out to us if you would like to chat.</p>
            <Link
              className="buttonWithArrow"
              target="_blank"
              to={"https://calendly.com/tribaja/intro-call"}
            >
              Contact Us
              <ArrowRight />
            </Link>
            <span>Don't like the forms? Drop us a line via email.</span>
            <div className="email">hello@tribaja.co</div>
          </div>
          <div className="footer__box">
            <Logo className="logo" />
            <div className="footer__box-columns">
              <div className="footer__box-column">
                <span>Company</span>
                <Link to={"/talent"}>Jobseekers</Link>
                <Link to={"/employer"}>Employers</Link>
                <Link to={"/partner"}>Partner</Link>
              </div>
              <div className="footer__box-column">
                <span>Product</span>
                <Link
                  target="_blank"
                  to={"https://calendly.com/d/4fm-mkb-bg9/book-a-demo"}
                >
                  Book a Demo
                </Link>
              </div>
            </div>
            <div className="footer__box-socials">
              <a
                href="https://www.facebook.com/Tribaja.co/"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook />
              </a>
              <a
                href="https://www.instagram.com/tribaja.co/"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram />
              </a>
            </div>
            <div onClick={() => scrollToTop()} className="footer__box-top">
              Back To The Top
            </div>
          </div>
        </main>
        <div className="footer__copyright">
          © Tribaja 2024. All rights reserved{" "}
          <div>
            <Link to={"/terms-and-conditions"}>
              Terms and Conditions
            </Link>
            <Link to={"/privacy-policy"}>
              Privacy Policy
            </Link>
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
