import React, { FC } from "react";
import {
  MicrosoftLogo
} from "../../assets/icons";

import "./CompaniesLogos.scss"
import { Ally, Doximity, GreenHouse, GSA, Lyft, TaskRabbit } from "../../assets/logos";
import Slider from "react-slick";


const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    }
  ]
};

const CompaniesLogos: FC = () => {
  return (
      <Slider {...settings} className="companies__logo">

      <img src={Lyft} alt="lyft logo"/>
      <MicrosoftLogo />
      <img src={Ally} alt="lyft logo"/>
      <img src={GreenHouse} alt="lyft logo"/>
      <img src={TaskRabbit} alt="lyft logo"/>
      <img src={GSA} alt="lyft logo"/>
      <img src={Doximity} alt="lyft logo"/>
       </Slider>
  );
};

export default CompaniesLogos;
