import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";

import "./TermsAndConditionsScreen.scss";
const TermsAndConditions = () => {
    const termsAndConditionsHtml = '<h1>Terms and Conditions</h1>' +
        '    <p><strong>Last updated:</strong> August 22, 2023</p>' +
        '    <p>Please read these terms and conditions carefully before using Our Service.</p>' +
        '    ' +
        '    <h2>Interpretation and Definitions</h2>' +
        '    ' +
        '    <h3>Interpretation</h3>' +
        '    <p>' +
        '        The words of which the initial letter is capitalized have meanings defined under the following conditions. ' +
        '        The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.' +
        '    </p>' +
        '    ' +
        '    <h3>Definitions</h3>' +
        '    <p>For the purposes of these Terms and Conditions:</p>' +
        '    <ul>' +
        '        <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</li>' +
        '        <li><strong>Country</strong> refers to: Delaware, United States</li>' +
        '        <li><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Tribaja, Inc., 651 North Broad Street, Suite 201, Middletown.</li>' +
        '        <li><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>' +
        '        <li><strong>Service</strong> refers to the Website.</li>' +
        '        <li><strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</li>' +
        '        <li><strong>Third-party Social Media Service</strong> means any services or content provided by a third-party that may be displayed, included or made available by the Service.</li>' +
        '        <li><strong>Website</strong> refers to Tribaja, accessible from <a href="https://www.tribaja.co/">https://www.tribaja.co/</a></li>' +
        '        <li><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>' +
        '    </ul>' +
        '' +
        '    <h2>Acknowledgment</h2>' +
        '    <p>' +
        '        These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. ' +
        '        These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.' +
        '    </p>' +
        '    <p>' +
        '        By accessing or using the Service You agree to be bound by these Terms and Conditions. ' +
        '        If You disagree with any part of these Terms and Conditions then You may not access the Service.' +
        '    </p>' +
        '    <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>' +
        '    <p>' +
        '        Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. ' +
        '        Please read Our <a href="/privacy-policy">Privacy Policy</a> carefully before using Our Service.' +
        '    </p>' +
        '' +
        '    <h2>Links to Other Websites</h2>' +
        '    <p>' +
        '        Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company. ' +
        '        We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.' +
        '    </p>' +
        '' +
        '    <h2>Termination</h2>' +
        '    <p>' +
        '        We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, ' +
        '        including without limitation if You breach these Terms and Conditions.' +
        '    </p>' +
        '' +
        '    <h2>Limitation of Liability</h2>' +
        '    <p>' +
        '        To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, ' +
        '        incidental, indirect, or consequential damages whatsoever.' +
        '    </p>' +
        '' +
        '    <h2>"AS IS" and "AS AVAILABLE" Disclaimer</h2>' +
        '    <p>' +
        '        The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. ' +
        '        Some jurisdictions do not allow the exclusion of certain types of warranties, so some or all of the above exclusions may not apply to You.' +
        '    </p>' +
        '' +
        '    <h2>Governing Law</h2>' +
        '    <p>' +
        '        The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service.' +
        '    </p>' +
        '' +
        '    <h2>Disputes Resolution</h2>' +
        '    <p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.</p>' +
        '' +
        '    <h2>Changes to These Terms and Conditions</h2>' +
        '    <p>' +
        '        We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. ' +
        '        By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms.' +
        '    </p>' +
        '' +
        '    <h2>Contact Us</h2>' +
        '    <p>If you have any questions about these Terms and Conditions, You can contact us:</p>' +
        '    <p>By email: <a href="mailto:hello@tribaja.co">hello@tribaja.co</a></p>';
    return (
        <>
            <div className="partnerScreen">
                <NavBar />
                <section
                    className="terms-and-conditions-container"
                    dangerouslySetInnerHTML={{__html: termsAndConditionsHtml}}
                />
            </div>
            <Footer />
        </>
    );
};

export default TermsAndConditions;
